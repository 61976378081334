import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Products from '../views/Products.vue'
import Favourite from '../views/Favourite.vue'
import ProductDetail from '../views/ProductDetail.vue'
import Cart from '../views/Cart.vue'
import Lastorder from '../views/Lastorder.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import Complete from '../views/Complete.vue'
import Category from '../views/Category.vue'
import Offers from '../views/offers.vue'
import ProductOffers from '../components/offers/AllProductinoffer.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Profile from '../views/Profile.vue'
import UpdateProfile from '../components/Profile/UpdateProfile.vue'
import Page from '../components/Profile/Page.vue'
import notfound404 from '../views/404.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/products/:params?',
    name: 'Product',
    component: Products
  },
  {
    path: '/productshaveoffer/:params?',
    name: 'ProductOffers',
    component: ProductOffers
  },
  {
    path: '/favourite',
    name: 'Favourite',
    component: Favourite
  },
  {
    path: '/products/detail/:id',
    name: 'Detail',
    component: ProductDetail
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/categories',
    name: 'Category',
    component: Category
  },
  {
    path: '/offers',
    name: 'Offers',
    component: Offers
  },
  {
    path: '/about-us',
    name: 'About',
    component: About
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/update-profile',
    name: 'UpdateProfile',
    component: UpdateProfile
  },
  {
    path: '/Page/:id',
    name: 'Page',
    component: Page
  },
  
  {
    path: '/cart/lastorder',
    name: 'Lastorder',
    component: Lastorder
  },

  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path:'/complete-order/:coupon?',
    name:'Complete',
    component: Complete

  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: notfound404,
    meta: { hideNavFooter: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to the top of the page on route change
    return { top: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = false; // Replace this with your actual authentication logic
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ path: '/login' });
  } else {
    next();
  }
});

export default router
