<template>
  <section class="mt-8 package">
    <div class="d-flex justify-space-between">
      <h2>{{$t('Latest_products')}}</h2>
      <!-- <v-list-item
        link
        class="pa-0"
        min-height="auto"
        style="border-bottom: 1px solid #03dac5; color: #03dac5"
      >
        عرض الكل
      </v-list-item> -->
    </div>
    <v-window
      v-model="window"
      class="elevation-0 position-relative mt-5"
      :show-arrows="false"
    >
      <v-window-item v-for="(group, i) in groupedProducts" :key="i">
        <v-row>
          
          <v-col
            cols="6"
            md="3"
            v-for="(product, index) in group"
            :key="index"
            class="h-100"
            data-aos="zoom-in-left" data-aos-duration="800"
            :data-aos-delay="100 * index"
          >
            <v-card
              class=""
              flat
              style="height: 280px"
              @click="detail(product.id)"
            >
              <v-img
                :src="product.cover_image"
                
                height="200"
                class="grey lighten-2"
              ></v-img>
              <h4 class="pa-2 pb-0">{{ product.name }}</h4>
              <!-- <v-card-subtitle v-html="product.description"></v-card-subtitle> -->
              <v-card-text v-if="product.current_price" class="pt-1">{{
                Math.trunc(product.current_price)
              }} 
              {{ $t('rs') }}

              </v-card-text>
              <v-card-text v-else class="pt-1">{{
                Math.trunc(product.base_price)
              }} 
              {{ $t('rs') }}

              </v-card-text>
              <!-- Add more product details here -->
            </v-card>
          </v-col>
        </v-row>
      </v-window-item>
      <div
        class="position-absolute w-100 d-flex justify-space-between"
        style="top: 40%"
      >
        <v-btn icon @click="next" class="right_btn">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon @click="prev" class="left_btn">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </v-window>
  </section>
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import cardimg from "../../../src/assets/image/photo.png";
export default {
  data() {
    return {
      products: [],
      window: 0,

    };
  },
  computed: {
    // Group the products into sub-arrays of 3
    groupedProducts() {
      const groups = [];
      for (let i = 0; i < this.products.length; i += 4) {
        groups.push(this.products.slice(i, i + 4));
      }
      return groups;
    },
  },
  methods:{
    // /products?sort=-total_orders
    next() {
      this.window++;
      if (this.window >= this.groupedProducts.length) {
        this.window = 0; // Wrap around to the first window
      }
    },
    prev() {
      this.window--;
      if (this.window < 0) {
        this.window = this.groupedProducts.length - 1; // Wrap around to the last window
      }
    },
    detail(id) {
      this.$router.push({ name: "Detail", params: { id } });
    },
    async productcreate() {
      let res = await CrudDataService.getAll(`products?sort=-created_at`);
      this.products = res.data.data.data;
    },
  },
  mounted() {
    this.productcreate();
  },
};
</script>
<style>
.package .v-window__controls {
  flex-direction: row-reverse;
}
.package .v-btn--icon.v-btn--density-default {
  width: 55px;
  height: 44px;
}
.package .v-btn--icon {
  border-radius: 30px;
  box-shadow: none;
}

.package .v-window__controls .v-window__left {
  left: -35px;
  background: #dddada;
  color: #fff;
}
.package .v-window__controls .v-window__right {
  right: -35px;
  background: #dddada;
  color: #fff;
}
</style>
