<template>
  <section class="mt-8 slide" data-aos-offset="200" data-aos="fade-up">
    <v-row>
      <v-col cols="12" md="4" sm="5" class="py-0">
        <div
          class="d-flex flex-column justify-center ps-10 h-100"
          style="color: #fff"
          height="100%"
        >
          <p style="font-size: 21px">
            {{ slidebanner ? slidebanner.value : '' }}
          </p>
        </div>
      </v-col>
      <v-col cols="12" md="8" sm="7" class="py-0">
        <v-img :src="slide" class="slidimg" align="center" justify="center">
        </v-img>
        <div
          class="d-flex flex-column justify-center ps-10 h-100 itemimg"
          height="100%"
          style="position: absolute; top: 0; left: 0"
        >
          <v-btn
            style="background-color: #6ba540"
            class="text-white ms-auto me-12"
            width="120px"
            @click="navigateTo()"
          >
            {{ $t("Shop_now") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import AOS from "aos";
import image from "../../../src/assets/image/Rectangl.png";

export default {
  data() {
    return {
      slide: image,
      slidebanner: "",
    };
  },
  methods: {
    navigateTo() {
      this.$router.push({ name: "Product" });
    },
    async banner() {
      let res = await CrudDataService.getAll("settings");
      console.log(res.data);
      res.data.data.forEach((Element) => {
        if (Element.key === "banner_2") {
          console.log(Element.value);
          this.slidebanner = Element;
        }
      });
    },
  },
  mounted() {
    this.banner();
    AOS.init();
  },
};
</script>
<style>
.slide {
  background: url("../../assets/image/Rectangle.png");
}

@media (max-width: 767.98px) {
  .slide .v-img__img--contain {
    display: none;
  }
}
</style>
