<template>
  <section class="sideone position-relative">
    <v-row style="align-items: center" justify="space-between">
      <v-col cols="12" md="6" class="px-0">
        <v-img :src="icon" class="pa-3 bg-sec">
          <img :src="logo" alt="" />
          <div
            class="sec-login"
            style="
              width: 400px;
              margin: auto;
              height: 50%;
              display: grid;
              place-items: center start;
            "
          >
            <div style="width: 100%">
              <h3 class="mb-3">
                {{ $t('LogIn') }}
                </h3>
              <v-text-field
                type="rext"
                variant="solo"
                :placeholder="$t('phone_number')+'/'+ $t('email')"
                class="mb-5 "
                v-model="loginForm.phone"
                hide-details
              ></v-text-field>
              <div v-if="invalidPhoneNumber" class="text-red mb-4">
                {{ validationMessage }}
              </div>
              <div class="text-red mb-4">
                {{ errorMessage ? errorMessage : "" }}
              </div>
              <!-- <v-btn v-bind="props" text="Open Dialog"> </v-btn> -->
              <v-btn
                width="100%"
                height="50px"
                class="text-white"
                color="#6BA540"
                style="box-shadow: rgb(77 71 195 / 20%) 0px 2px 16px 0px"
                @click="handleLogin"
              >
                {{ $t('LogIn') }}
              </v-btn>
              <v-dialog width="500" v-model="openotp">
                <v-card
                  class="py-11 px-8 text-center ma-0 over"
                  elevation="12"
                  max-width="500"
                  width="100%"
                  v-if="otpmessage"
                >
                  <div style="font-size: 19px; font-weight: 500">
                    {{ otpmessage }}
                    {{ $t('OTP_phone') }}
                  </div>

                  <v-sheet color="surface">
                    <v-otp-input
                      v-model="otp"
                      length="4"
                      type="number"
                      variant="solo"
                    ></v-otp-input>
                  </v-sheet>
                  <div class="text-red mb-4">
                {{ errorMessage ? errorMessage : "" }}
              </div>
                  <v-btn
                    class="my-4 mx-auto"
                    color="#6BA540"
                    height="40"
                    :text="$t('Verify')"
                    variant="flat"
                    width="50%"
                    @click="handleverify"
                  ></v-btn>
                </v-card>
              </v-dialog>
              <div class="text-center pt-2">
                {{ $t('Don_account') }}  
                <p
                  class="d-inline"
                  style="color: #03dac5; cursor: pointer"
                  @click="signlink"
                >
                  {{ $t('Create_account') }} 
                </p>
              </div>
            </div>
          </div>
          <div class="w-100 d-flex justify-end arrowimg">
            <img :src="arrow" alt="" />
          </div>
        </v-img>
      </v-col>
      <div
        class="position-absolute logimg"
        style="right: 40%; top: 25px; width: 280px; z-index: 111"
      >
        <v-img :src="Vect" >
          <div
            class="d-flex justify-center align-center w-100 h-100"
            style="font-size: 28px; font-weight: 500"
          >
            <p class="text-center">{{ $t('LogIn') }} </p>
          </div>
        </v-img>
        <!-- <img :src="Vect"  alt="" /> -->
      </div>

      <v-col cols="12" md="5" class="px-0">
        <v-img :src="Loginamico" class="imgsection"> </v-img>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import logo from "../../../assets/image/logo.png";
import image from "../../../assets/image/Vector.png";
import Loginamico from "../../../assets/image/Loginamico.svg";
import Vect from "../../../assets/image/Vect.png";
import arrow from "../../../assets/image/Grouparrow.png";
import OTP from "./OTP.vue";
import CrudDataService from "@/Service/CrudDataService";
export default {
  data() {
    return {
      otp: "",
      logo: logo,
      icon: image,
      Loginamico: Loginamico,
      Vect: Vect,
      arrow: arrow,
      loginForm: {
        phone: "",
      },
      invalidPhoneNumber: false,
      validationMessage: "",
      errorMessage: "",
      otpmessage: "",
      openotp: false,
    };
  },
  components: {
    OTP,
  },
  methods: {
    validatePhoneNumber() {
      // Simple check to ensure the phone number does not start with a negative sign
      // and contains only digits. You can replace this with a more robust validation if needed.
      const isNegative = this.loginForm.phone.startsWith("-");
      const isNumeric = /^\d+$/.test(this.loginForm.phone);

      this.invalidPhoneNumber = isNegative || !isNumeric;

      // If you have a localization library like vue-i18n, you can use it to switch messages
      this.validationMessage = "ادخل رقم هاتف صحيح";
    },
    async handleLogin() {
      await CrudDataService.create(`login`, this.loginForm)
        .then((response) => {
          this.otpmessage = response.data.message;
          this.openotp = true;
          this.errorMessage=""
        })
        .catch((error) => {
          this.errorMessage = error.data.message;
        });
    },
    async handleverify() {
      await CrudDataService.create(`verify-otp`, {
        phone: this.loginForm.phone,
        otp: this.otp,
      })
        .then((response) => {
          this.$router.push({ name: 'home' })
          localStorage.setItem('authToken', response.data.data.token);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          this.errorMessage = error.data.message;
        });
    },
    signlink() {
      this.$router.push({ name: "SignUp" });
    },
  },
};
</script>
<style>
[dir='ltr'] .bg-sec .v-img__img--contain {
    transform: rotateY(180deg);
}
.tel input {
  text-align: end !important;
}
.sideone .v-input--density-default .v-field--variant-outlined,
.sideone .v-input--density-default .v-field--single-line,
.sideone .v-input--density-default .v-field--no-label {
  background: #dbf8db;
    color: #6BA540;
}
@media (max-width: 991.98px) {
  .sideone .imgsection {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .sideone .logimg {
    display: none;
  }
  .sideone .arrowimg {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  .sideone .sec-login {
    width: 90% !important;
  }
  .sideone .bg-sec {
    object-fit: cover !important;
  }
}
.v-overlay__scrim {
  background: rgba(187, 134, 252, 0.762);
}
</style>
