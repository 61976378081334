<template>
    <v-container>
      <About />
    </v-container>
</template>

<script>
import About from "../components/About/About.vue";

export default ({
  components: {
    About,
  },
});
</script>
