<template>
 <contactinput/>
</template>
<script>
import contactinput from '../Contact/contactinput.vue';
export default {
  components:{
    contactinput
  },
  data() {
    return {
     
    };
  },
 
};
</script>