<template>
  <div>
    <div
      v-if="
        $route.path == '/cart' ||
        $route.path == '/cart/lastorder' ||
        $route.path == '/complete-order'
      "
    ></div>
    <section class="subscribe py-4 text-center" v-else>
      <v-col cols="12" md="4" sm="7" xs="9" class="mx-auto">
        <div class="mx-auto" data-aos-offset="200" data-aos="fade-up">
          <h3 class="py-1">{{ $t("Subscribe_receive_latest") }}</h3>
          <p class="pb-3">
            {{ $t("Get_news_world") }}
          </p>
          <div class="d-flex justify-center align-center">
            <!-- <v-text-field
            density="compact"
            variant="solo"
            prepend-inner-icon="mdi-email-outline"
            :placeholder="$t('email')"
            single-line
            hide-details
            rounded="0"
            class="search"
          >
          </v-text-field> -->
            <v-btn
              style="background-color: #6BA540"
              class="rounded-0 text-white"
              @click="redirectto('/contact-us')"
            >
              {{ $t("subscription") }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </section>
  </div>
</template>
<script>
import AOS from "aos";

import cardimg from "../../../src/assets/image/photo.png";
export default {
  data: () => ({
    cardimg: cardimg,
  }),
  methods: {
    redirectto(path) {
      this.$router.push(path);
    },
  },
  mounted() {
    AOS.init();
  },
};
</script>
<style scoped>
.subscribe {
  background: linear-gradient(
    180deg,
    rgba(6, 182, 87, 0.26) 0%,
    rgba(63, 171, 13, 0.22) 44.27%,
    rgba(29, 214, 75, 0.17) 67%,
    rgba(40, 213, 12, 0.17) 97%,
    rgba(19, 215, 137, 0.16) 99.97%,
    rgba(9, 215, 199, 0.16) 99.98%,
    rgba(6, 217, 198, 0) 99.99%,
    rgba(59, 218, 3, 0) 100%
  );
}
</style>
<style>
.subscribe .search .v-input__control {
  width: 99%;
}
.subscribe .v-btn.v-btn--density-default {
  height: calc(var(--v-btn-height) + 3px);
}
.subscribe .v-text-field .v-field--single-line input {
  padding: 0 !important;
}
</style>
