<template>
  <section v-if="category.length > 0">
    <v-breadcrumbs :items="items">
      <template v-slot:title="{ item }">
        {{ $t(item.title) }}
      </template>
    </v-breadcrumbs>
    <v-row class="my-4">
      <v-col
        cols="12"
        lg="2"
        md="3"
        sm="6"
        justify="center"
        align="center"
        v-for="(item, i) in category"
        :key="i"
        data-aos="fade-down-left"
        data-aos-duration="600"
        :data-aos-delay="200 * i"
      >
        <v-card
          style="background-color: #6ba54059"
          class="pa-4 d-flex"
          v-if="item.image"
          @click="goSubcategory(item)"
          height="175"
        >
          <v-img :src="item.image" class="rounded"></v-img>
        </v-card>
        <h3 class="pt-2 font-weight-medium">
          {{ item.name }}
        </h3>
      </v-col>
      <v-row v-if="opencard">
        <v-col
          cols="12"
          md="3"
          justify="center"
          align="center"
          v-for="(item, i) in subcategory"
          :key="i"
        >
          <v-card
            style="background-color: #6ba54059"
            class="pa-4 d-flex"
            v-if="item.image"
            @click="gotoproduct(item.id)"
            height="175"
          >
            <v-img :src="item.image" class="rounded"></v-img>
          </v-card>
          <h3 class="pt-2 font-weight-medium">
            {{ item.name }}
          </h3>
        </v-col>
      </v-row>
    </v-row>
  </section>
  <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-else
   >

<section class="cate">
</section>
 <v-progress-circular
      indeterminate
      color="green"
    ></v-progress-circular>
    </section>
  <!-- <v-container v-else>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader
          class="mx-auto border-0"
          type="paragraph"
        ></v-skeleton-loader>
      </v-col>

      <v-col cols="2" v-for="text in texts" :key="text">
        <v-skeleton-loader
          class="mx-auto border-0"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container> -->
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";

export default {
  data: () => ({
    texts: [1, 2, 3, 4, 5, 6],
    category: [],
    items: [
      {
        title: "Home",
        disabled: false,
        href: "/",
      },
      {
        title: "Categories",
        disabled: false,
        href: "/categorie",
      },
    ],
    opencard: false,
    subcategory: [],
  }),
  methods: {
    goSubcategory(item) {
      if (item.sub_categories.length>0) {
        this.opencard = true;
      this.subcategory = item.sub_categories;
      }else{
        let idcat=item.id
      this.gotoproduct(idcat)
      }    
    },
    gotoproduct(params) {
      this.$router.push({ name: "Product", params: { params } });
    },
    async allcategories() {
      let res = await CrudDataService.getAll("categories");
      this.category = res.data.data.data;
    },
  },
  mounted() {
    this.allcategories();
  },
};
</script>
<style>
.v-breadcrumbs {
  color: #8b96a5;
}
</style>
