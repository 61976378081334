<template>
    <v-container>
        <section>
          <v-breadcrumbs :items="items">
      <template v-slot:title="{ item }">
        {{ $t(item.title) }}
      </template>
    </v-breadcrumbs>
    
    <section v-if="content">
        <h1 class='text-center mb-5'>{{ content.title }}</h1>
      <div v-html="content.content"></div>
    </section>
    <section v-else-if="content===''">
      <v-card class="hint my-7 py-2" elevation="0">
        <v-card-text>
          لا يوجد صفحة
          {{ items[1].title }}
          الان
        </v-card-text>
      </v-card>
    </section>
    <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-else
   >

<section class="cate">
</section>
 <v-progress-circular
      indeterminate
      color="green"
    ></v-progress-circular>
    </section>
  </section>
    </v-container>

</template>
<script>
import CrudDataService from "@/Service/CrudDataService";

export default {
  data: () => ({
    content: null,
    items: [
      {
        title: "Home",
        disabled: false,
        href: "/",
      },
      {
        title: "",
        disabled: false,
      },
    ],
  }),
  beforeRouteUpdate(to, from, next) {
    // This guard is called when the route changes, but this component is reused
    this.allpages(to.params.id);
    next();
  },
  methods: {
    async allpages(id) {
      let res = await CrudDataService.get("pages",`${id}`);
      this.content =  res.data.data
      this.items[1].title=this.content.title
      // res.data.data.forEach((element) => {
      //   if (element.slug == this.$route.params.slug) {
      //       this.items[1].title=element.title

      //     this.content = element;
      //   }
      // });
    },
  },
  mounted() {
    this.allpages(this.$route.params.id);
  },
};
</script>
<style>
.v-breadcrumbs {
  color: #8b96a5;
}
</style>
<style scoped>
.hint {
  background: #616160;
  color: white;
  text-align: center;
}
.hint .v-card-text {
  font-size: 18px;
}
</style>
