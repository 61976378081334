<template>
  <v-card class="mt-8 py-3">
    <v-row  class=" py-3" style="background: #fff">
    <v-col cols="12" lg="3"  sm="6" xs="12">
      <v-card height="100%" elevation="0">
        <div
          class="pa-3 d-flex flex-column"
          style="height: 100%"
        >
          <h4 class="text-h5">{{ $t('Exclusive_offers_packages') }}</h4>
          <!-- <div class="d-flex">
            <p
              style="background: #03dac5; color: #fff; width: 53px"
              class="py-2 text-center ma-1"
            >
              5
              <span class="d-block"> يوم </span>
            </p>
            <p
              style="background: #03dac5; color: #fff; width: 53px"
              class="py-2 text-center ma-1"
            >
              12
              <span class="d-block"> ساعة </span>
            </p>
            <p
              style="background: #03dac5; color: #fff; width: 53px"
              class="py-2 text-center ma-1"
            >
              44
              <span class="d-block"> دقيقة </span>
            </p>
            <p
              style="background: #03dac5; color: #fff; width: 53px"
              class="py-2 text-center ma-1"
            >
              67
              <span class="d-block"> ثانية </span>
            </p>
          </div> -->
        </div>
      </v-card>
    </v-col>
    <v-col cols="12" lg="2"  sm="6" xs="6" justify="center" align="center" class="cartanimg" v-for="
    item in items" :key="item.id">
    <v-card  class="elevation-0" @click="gotoproduct(item.id)">

      <img :src="item.image" alt="" v-if="item.image"/>
      <img :src="cardimg" alt="" v-else class="w-100 rounded"/>
      <p class="mb-2"> {{ item.title }}</p>
      <!-- <span
        style="background: #ffe0b0; color: #eb001b; border-radius: 20px"
        class="px-3"
      >
        25%
      </span> -->
      </v-card>
    </v-col>

  </v-row>
  </v-card>

</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import cardimg from "../../../src/assets/image/offer.jpg";
export default {
  data: () => ({
    cardimg: cardimg,
    items:[]
  }),
  methods: {
    async offer() {
      let res = await CrudDataService.getAll("offers");
    this.items=  res.data.data
       
    },
    gotoproduct(params){
      this.$router.push({ name: "ProductOffers", params: { params } });
    },
  },
  mounted(){
    this.offer()
  }
};
</script>
<style scoped>
.cartanimg {
  border-left: 1px solid #eeeeee;
  width: calc(100% / 5);
}
.cartanimg:last-child {
  border-left: none;
}
</style>
