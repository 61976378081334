<template>
  <section style="background: #eeeeee">
    <v-container>
      <Braedcrumb />
      <v-row>
            <Fillter/>
      
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import Braedcrumb from "../components/Product/Braedcrumb.vue";
import Fillter from "../components/Product/Fillter.vue";
export default defineComponent({
  components: {
    Braedcrumb,
    Fillter,
  },
});
</script>
