<template>
  <section class="mt-8">
    <div class="d-flex justify-space-between">
      <h2> {{ $t('Best_selling') }}</h2>
      <!-- <v-list-item
        link
        class="pa-0"
        min-height="auto"
        style="border-bottom: 1px solid #03dac5; color: #03dac5"
      >
        عرض الكل
      </v-list-item> -->
    </div>
    <div class="d-flex mt-5 flex-sm-row flex-column">
      <v-card
        class="mx-auto pb-4 mostcard mb-2"
        v-for="(item, i) in products"
        :key="i"
        data-aos="zoom-in-left" data-aos-duration="800"
            :data-aos-delay="100 * i"
            @click="detail(item.id)"
       
      >
        <v-img :src="item.cover_image" cover height="180"></v-img>

        <v-card-item class="pt-0">
          <h4 v-if="item.current_price" class="pt-1">{{
                Math.trunc(item.current_price)
              }}
              {{ $t('rs') }}
          </h4>
          <span class="me-1 text-grey-lighten-1 font-weight-light d-block">
            {{ item.name }}
          </span>
        </v-card-item>
      </v-card>
    </div>
  </section>
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import cardimg from "../../../src/assets/image/photo.png";
export default {
  data() {
    return {
      products: [ ],
    };
  },
  methods:{
  async productlatest() {
      let res = await CrudDataService.getAll(`products?sort=--total_orders`);
      this.products = res.data.data.data.slice(0,5);
    },
    detail(id) {
      this.$router.push({ name: "Detail", params: { id } });
    },
  },
    mounted() {
    this.productlatest();
  },
};
</script>
<style scoped>
.mostcard {
  width: calc(90% / 5);
}

@media (max-width: 767.98px) {
  .mostcard {
    width: 100%;
  }
}
</style>
