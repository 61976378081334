<template>
  <section>
    <v-row v-if="items.length>0">
      <v-col cols="12" md="3" v-for="item in items" :key="item.id">

        <v-card class="mb-3" style="height: 400px">
          <!-- <button @click="removeFromFavorites(item.id)">
            <v-icon icon="mdi-close" color="#DEE2E7"></v-icon>
          </button> -->

          <v-img :src="item.cover_image" cover 
          height="200"
          ></v-img>

          <div class="d-flex justify-space-between align-center pa-2">
            <p  @click="detail(item.id)" >
              {{ item.name }}
            </p>
            <!-- <v-icon
              icon="mdi-heart-outline"
              style="
                color: #eb001b;
                border: 1px solid #dee2e7;
                padding: 15px 15px 14px 15px;
                border-radius: 4px;
                box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
              "
            ></v-icon> -->
          </div>

          <v-card-title class="pa-0">
            <v-card-text v-if="item.current_price" class="pt-1 pb-0">{{
                Math.trunc(item.current_price)
              }}</v-card-text>
              <v-rating
                    :model-value="item.rating"
                    @update:model-value="updateRating(product.id, product.rating)"
                    color="amber"
                    density="compact"
                    half-increments
                    size="small"
                    class="px-3 pt-0"
                  ></v-rating>
          </v-card-title>

          
          <v-card-actions style="justify-content: center">
            <v-btn
              style="
                color: #6BA540;
                border: 1px solid #6BA540;
                border-radius: 20px;
              "
              @click="addcart(item.id)"
            >
           
              <v-icon icon="mdi-cart-outline" class="px-3"></v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <section v-else>
      
    </section>
    <v-snackbar v-model="showSnackbar" color="green-darken-3
">
   {{ $t('Added_successfully') }}  
    <template v-slot:action="{ attrs }">
      <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
  </section>
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
export default {
  data() {
    return {
      items: [ ],
      showSnackbar :false

    };
  },
  methods: {
    async addcart(id){
    let res=await CrudDataService.create(`cart`,{
        product_id: id,
        quantity: 1,
      })
      if (res.data.success) {
      this.showSnackbar=true
    }
    },
    removeFromFavorites(itemId) {
      this.items = this.items.filter((item) => item.id !== itemId);
    },
    async favorite(){
      let res = await CrudDataService.getAll('user/favorites').then((res)=>{
        res.data.data.data.forEach(element => {
        element.product_id
         CrudDataService.get('products',`${element.product_id}`)
         .then(response => {
          this.items.push(response.data.data);
          })
      });
      }).catch((err)=>{
console.log(err);
      })
     
    },
    detail(id) {
      this.$router.push({ name: "Detail", params: { id } });
    },
  },
  mounted(){
    this.favorite()
  }
};
</script>
