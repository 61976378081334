<template>
  <section class="sideone position-relative">
    <v-row style="align-items: center" justify="space-between">
      <v-col cols="12" md="6" class="px-0">
        <v-img class="pa-3 bg-sec" :src="icon" >
          <img :src="logo" alt="" />
          <div
            class="sec-login"
            style="
              width: 400px;
              margin: auto;
              height: auto;
              display: grid;
              place-items: center start;
            "
          >
            <div style="width: 100%">
              <h3 class="mb-3">{{ $t('Create_account') }} </h3>

              <v-text-field
                type="text"
                variant="solo"
                :placeholder="$t('First_Name')"
                class="mb-2"
                v-model="formData.fname"

              ></v-text-field>
              <v-text-field
                type="text"
                variant="solo"
                :placeholder="$t('latest_Name')"
                class="mb-2"
                v-model="formData.lname"

              ></v-text-field>
              <v-text-field
                type="number"
                variant="solo"
                :placeholder="$t('phone_number')"
                class="mb-2"
                v-model="formData.phone"

              ></v-text-field>
              <v-text-field
                type="email"
                variant="solo"
                :placeholder="$t('email')"
                class="mb-2"
                v-model="formData.email"
              ></v-text-field>

              <multiselect
                :options="optioncountries"
                :searchable="true"
                label="name"
                class="selectinput mb-2 pa-2"
                :placeholder="$t('Country')"
                v-model="formData.country_id"
              ></multiselect>
              <div class="text-red mb-4">
                {{ errorMessage ? errorMessage : "" }}
              </div>
              <v-btn
                width="100%"
                height="50px"
                class="text-white"
                color="#6BA540"
                style="box-shadow: rgb(77 71 195 / 20%) 0px 2px 16px 0px"
                @click="handleRegister()"
              >
                {{ $t('Create_account') }}
              </v-btn>
              <v-dialog width="500" v-model="openotp">
                <v-card
                  class="py-11 px-8 text-center ma-0 over"
                  elevation="12"
                  max-width="500"
                  width="100%"
                  v-if="otpmessage"
                >
                  <div style="font-size: 19px; font-weight: 500">
                    {{ otpmessage }}
                 {{ $t('OTP_phone') }}
                  </div>

                  <v-sheet color="surface">
                    <v-otp-input
                      v-model="otp"
                      length="4"
                      type="number"
                      variant="solo"
                    ></v-otp-input>
                  </v-sheet>
                  <div class="text-red mb-4">
                    {{ errorMessage ? errorMessage : "" }}
                  </div>
                  <v-btn
                    class="my-4 mx-auto"
                    color="#6BA540"
                    height="40"
                    :text="$t('Verify')"
                    variant="flat"
                    width="50%"
                    @click="handleverify"
                  ></v-btn>
                </v-card>
              </v-dialog>
            </div>
          </div>
          <div class="text-center pt-2">
{{ $t('Already_account') }}
            <p
              class="d-inline"
              style="color: #03dac5; cursor: pointer"
              @click="loginlink"
            >
              {{ $t('LogIn') }} 
            </p>
          </div>
          <!-- <v-card-subtitle
            class="text-center"
            style="line-height: 29.98px; font-size: 16px"
          >
            او قم بالتسجيل من خلال
          </v-card-subtitle>
          <div class="d-flex justify-center align-center mt-4">
            <img :src="google" class="mx-2" style="width: 40px" />
            <img :src="apple" class="mx-2" style="width: 40px" />
            <img :src="Facebook" class="mx-2" style="width: 40px" />
          </div> -->
        </v-img>
      </v-col>
      <div
        class="position-absolute logimg"
        style="right: 40%; top: 25px; width: 200px; z-index: 111"
      >
        <v-img :src="Vect">
          <div
            class="d-flex justify-center align-center w-100 h-100"
            style="font-size: 28px; font-weight: 500"
          >
            <p class="text-center">{{ $t('Create_account') }}</p>
          </div>
        </v-img>
        <!-- <img :src="Vect"  alt="" /> -->
      </div>

      <v-col cols="12" md="5" class="px-0">
        <v-img :src="Loginamico" class="imgsection">
          <div class="w-100 h-100 d-flex justify-Start align-end arrowimg">
            <img :src="arrow" alt="" style="height: 38px" />
          </div>
        </v-img>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import logo from "../../../assets/image/logo.png";
import icon from "../../../assets/image/Vector.png";
import Loginamico from "../../../assets/image/Loginamico.svg";
import Vect from "../../../assets/image/Vect.png";
import arrow from "../../../assets/image/Underline.png";
import google from "../../../assets/image/google.png";
import apple from "../../../assets/image/apple.png";
import Facebook from "../../../assets/image/Facebook.png";
import CrudDataService from "@/Service/CrudDataService";
import { useAuth } from "@/http-common";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      logo,
      icon,
      Loginamico,
      Vect,
      arrow,
      google,
      apple,
      Facebook,
      formData: {
        fname: "",
        lname: "",
        phone: "",
        email: "",
        country_id: "",
      },
      optioncountries: [],
      errorMessage: "",
      otpmessage: "",
      openotp: false,
      otp:''
    };
  },
  methods: {
    loginlink() {
      this.$router.push({ name: "Login" });
    },
    async getcontry() {
      let res = await CrudDataService.getAll("countries");
      this.optioncountries = res.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },
    async handleRegister() {
      await CrudDataService.create(`register`, this.formData)
        .then((res) => {
          console.log(res);
          this.errorMessage=""
          this.otpmessage = res.data.message;
          this.openotp = true;
          // localStorage.setItem("authToken", res.data.data.token);
          // this.$router.push({ name: "home" });
          // const {isLoggedIn} = useAuth();
          // isLoggedIn()
        })
        .catch((error) => {
          console.log(error.data);
          this.errorMessage = error.data.message;
        });
    },
    async handleverify() {
      await CrudDataService.create(`verify-register-otp`, {
        phone: this.formData.phone,
        otp: this.otp,
      })
        .then((response) => {
          this.$router.push({ name: "home" });
          localStorage.setItem("authToken", response.data.data.token);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          this.errorMessage = error.data.message;
        });
    },
  },
  mounted() {
    this.getcontry();
  },
};
</script>
<style>
[dir='ltr'] .bg-sec .v-img__img--contain {
    transform: rotateY(180deg);
}
[dir='ltr'] .multiselect-single-label {
width: 100%;
}
.sideone .v-input--density-default .v-field--variant-outlined,
.v-input--density-default .v-field--single-line,
.v-input--density-default .v-field--no-label {
  background: #dbf8db;
    color: #6BA540;
}
@media (max-width: 991.98px) {
  .sideone .imgsection {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .sideone .logimg {
    display: none;
  }
  .sideone .arrowimg {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  .sideone .sec-login {
    width: 90% !important;
  }
  .sideone .bg-sec {
    object-fit: cover !important;
  }
}
.selectinput.multiselect,
.selectinput .multiselect-search {
  background: #dbf8db;
  color: #6BA540;
}
.selectinput .multiselect-placeholder {
  right: 0;
  padding-right: 7px;
  color: #6ca54093;
}
</style>
