<template>
  <v-card
    class="py-11 px-8 text-center ma-0 over"
    elevation="12"
    max-width="500"
    width="100%"
  >
    <div style="font-size: 19px; font-weight: 500">
      تم ارسال رمز OTP الي هاتفك الجوال
      <p class="d-inline text-decoration-underline" style="color: #6BA540;font-size: 18px; font-weight: 400">اعادة الارسال</p>
    </div>
  
    <v-sheet color="surface">
      <v-otp-input
        v-model="otp"
        length="4"
        type="password"
        variant="solo"
      ></v-otp-input>
    </v-sheet>

    <v-btn
      class="my-4 mx-auto"
      color="#6BA540"
      height="40"
      text="تأكيد"
      variant="flat"
      width="50%"
    ></v-btn>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    otp: "",
  }),
};
</script>
<style>
.v-overlay__scrim {
    background: rgba(187, 134, 252, 0.762);
}
</style>