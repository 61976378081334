<template>
    <v-container>
      <Category />
    </v-container>
</template>

<script>
import Category from "../components/Category/Category.vue";

export default ({
  components: {
    Category,
  },
});
</script>
