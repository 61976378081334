<template>
    <v-container>
      <OrderProduct />
    </v-container>
</template>

<script>
import { defineComponent } from "vue";
import OrderProduct from "../components/OrderedLast/OrderProduct.vue";

export default defineComponent({
  components: {
    OrderProduct
  },
});
</script>
