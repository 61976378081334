<template>
  <section v-if="items.length > 0">
    <h3 class="mb-3">{{ $t("cart") }}</h3>
    <section class="bg-white rounded">
      <v-card
        class="pa-3"
        v-for="item in items"
        :key="item.id"
        style="background-color: transparent"
        elevation="0"
      >
        <v-row>
          <v-col cols="12" sm="2" xs="12">
            <v-img
              :src="item.cover_image"
              height="200"
              cover
              class="rounded"
            ></v-img>
          </v-col>
          <v-col cols="12" md="8" sm="7" xs="12">
            <div width="80%">
              <p @click="detail(item.product_id)" style="cursor: pointer">
                {{ item.name }}
              </p>

              <p class="text-grey-lighten-1">
                {{ item.text }}
              </p>
              <v-btn
                class="text-red mt-3"
                variant="outlined"
                style="border: 1px solid#DEE2E7"
                @click="remove(item.id)"
              >
                {{ $t("delete") }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="2" sm="3" xs="12">
            <div class="d-flex flex-sm-column justify-space-between">
              <v-card-title>
                {{ item.total_price ? item.total_price : "" }}
                {{ $t("rs") }}
              </v-card-title>
              <div class="d-flex justify-space-between align-center">
                <!-- <button @click="increaseQuantity(item)">
                  <v-icon> mdi-plus-box </v-icon>
                </button> -->
                <span>{{ $t("Quantity") }}</span>

                <input
                  type="number"
                  id="quantity"
                  min="1"
                  class="form-control w-50  rounded text-center"
                  v-model.number="item.quantity"
                  @change="updateCartItem(item)"
                />
                <!-- <button @click="decreaseQuantity(item)">
                  <v-icon> mdi-minus-box </v-icon>
                </button> -->
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider class="mx-4 my-3"></v-divider>
      </v-card>
      <div
        class="pa-md-5 pa-2 pt-2 d-flex flex-md-row flex-column justify-md-space-between justify-center align-items-center"
      >
        <v-btn
          class="text-red"
          variant="outlined"
          style="border: 1px solid#DEE2E7"
          @click="removeall()"
        >
          {{ $t("delete_all") }}
        </v-btn>
        <v-btn
          style="background-color: #6ba540; color: white"
          width="200"
          variant="text"
          class="mt-md-0 mt-2"
          @click="golast"
        >
          {{ $t("Previous_orderes") }}
          <v-icon icon="mdi-arrow-left" class="ms-2 arrow"></v-icon>
        </v-btn>
      </div>
    </section>
    <section class="bg-white rounded mt-5 pa-4 cobonn" style="width: 35%">
      <p class="pb-4">
        {{ $t("discount_coupon") }}
      </p>
      <div class="d-flex justify-center align-center border rounded">
        <v-text-field
          density="compact"
          variant="solo"
          :placeholder="$t('Add_coupon')"
          class="pa-0 coupon"
          hide-details
          rounded="0"
          style="border-left: 1px solid #e0e0e0"
          v-model="coupon"
        >
        </v-text-field>
        <v-btn
          style="color: #6ba540"
          class="rounded-0"
          elevation="0"
          @click="applycoupon(coupon)"
        >
          {{ $t("Done") }}
        </v-btn>
      </div>
      <div>{{ message ? message : "" }}</div>
    </section>
    <section class="bg-white rounded mt-5 pa-4 total">
      <div class="d-flex justify-center align-center border rounded">
        <div class="w-75 pa-2 coupon" style="border-left: 1px solid #e0e0e0">
          {{ $t("Total_cart") }}
        </div>
        <div class="w-25 pa-2 text-center">
          {{ totalcart }}
          {{ $t("rs") }}
        </div>
      </div>
    </section>
    <div class="d-flex justify-end">
      <v-btn
        class="rounded-0 mt-5"
        style="background-color: #6ba540; color: white"
        width="200"
        height="45"
        variant="text"
        @click="completeroute(coupon)"
      >
        {{ $t("Complete_order") }}
      </v-btn>
    </div>
  </section>
  <section v-else-if="items.length === 0" class="my-md-16 py-md-1">
    <h3 class="pb-md-16">{{ $t("cart") }}</h3>
    <v-card class="hint py-2 mb-md-16" elevation="0">
      <v-card-text>
        {{ $t("nothing") }}
        {{ $t("products") }}
        {{ $t("now") }}
      </v-card-text>
    </v-card>
  </section>
  <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-else
   >
<section class="cate">
</section>
 <v-progress-circular
      indeterminate
      color="green"
    ></v-progress-circular>
    </section>
  
</template>
<script>
import Multiselect from "@vueform/multiselect";
import CrudDataService from "@/Service/CrudDataService";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      texts: [1, 2, 3, 4, 5, 6],

      items: [],
      totalcart: "",
      coupon: "",
      message: "",
    };
  },
  methods: {
    applycoupon(coupon) {
      CrudDataService.create(`apply-coupon`, { coupon: coupon })
        .then((response) => {
          console.log(response.data);
          this.message = response.data.message;
          this.cart();
        })
        .catch((error) => {
          console.log(error.data.message);
          this.message = error.data.message;
        });
    },
    completeroute(coupon) {
      this.$router.push({
        name: "Complete",
        params: { coupon: this.coupon ? this.coupon : ""},
      });
    },
    golast() {
      this.$router.push({ name: "Lastorder" });
    },
    detail(id) {
      this.$router.push({ name: "Detail", params: { id } });
    },
    remove(itemId) {
      CrudDataService.delete("cart", itemId);
      this.items = this.items.filter((item) => item.id !== itemId);
    },
    async cart() {
      let res = await CrudDataService.getAll(`cart?code=${this.coupon}`).then((res)=>{
      this.items = res.data.data.items;
      this.totalcart = res.data.data.total;
      }).catch((err)=>{

        console.log(err);
      })
     
     
    },
    async updatecart(id, qun) {
      let res = await CrudDataService.put(`cart`, {
        product_id: id,
        quantity: qun,
      });
      this.items = res.data.data.items;
      this.totalcart = res.data.data.total;
    },
    updateCartItem(item){
      item.total_price = item.final_unit_price * item.quantity;
      this.updatecart(item.product_id, item.quantity);

    },
    // increaseQuantity(item) {
    //   item.quantity++;
    //   item.total_price = item.final_unit_price * item.quantity;
    //   this.updatecart(item.product_id, item.quantity);
    // },
    // decreaseQuantity(item) {
    //   if (item.quantity > 1) {
    //     item.quantity--;
    //     item.total_price = item.final_unit_price * item.quantity;
    //     this.updatecart(item.product_id, item.quantity);
    //   }
    // },
    removeall() {
      CrudDataService.deleteAll(`cart`);
      this.items = [];
      this.totalcart = "";
    },
  },
  mounted() {
    this.cart();
  },
};
</script>
<style>
.coupon .v-field--variant-solo,
.coupon .v-field--variant-solo-filled {
  box-shadow: none;
}
@media (max-width: 767.98px) {
  .cobonn {
    width: 100% !important;
  }
}
</style>
<style scoped>
.hint {
  margin-bottom: 100px !important;
  background: #616160;
  color: white;
  text-align: center;
}
.hint .v-card-text {
  font-size: 18px;
}
</style>
