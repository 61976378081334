<template>
    <div>
        <v-container v-if="products.length>0">
          <v-breadcrumbs :items="items">
      <template v-slot:title="{ item }">
        {{ $t(item.title) }}
      </template>
    </v-breadcrumbs>
          
            <v-row class="my-2">
          <v-col
            cols="12"
            md="4"
            v-for="(product, index) in products"
            :key="index"

          >
            <v-card class="mb-3 elevation-0" style="height: 100%">
              <v-img :src="product.cover_image" height="222" cover></v-img>

              <div class="d-flex justify-space-between align-center pa-2">
                <p @click="detail(product.id)" style="cursor: pointer">
                  {{ product.name }}
                </p>
                <v-icon
                  :icon="getIcon(product)"
                  @click="toggleHeart(product)"
                  style="
                    color: #0d6efd;
                    border: 1px solid #dee2e7;
                    padding: 15px 15px 14px 15px;
                    border-radius: 4px;
                    box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
                  "
                ></v-icon>
              </div>

              <v-card-text v-if="product.current_price" class="py-1">{{
                Math.trunc(product.current_price)
              }}</v-card-text>

              <div class="d-flex">
                <v-rating
                  :model-value="product.rating"
                  color="amber"
                  density="compact"
                  half-increments
                  readonly
                  size="small"
                  class="px-3"
                ></v-rating>
                <p style="color: #ff9017" v-if="product.rating">
                  {{ product.rating }}
                </p>
                <v-icon
                  icon="mdi-circle-medium"
                  color="#DEE2E7"
                  class="d-sm-inline d-none"
                  v-if="product.total_orders_quantity"
                ></v-icon>
                <p style="color: #8b96a5" v-if="product.total_orders_quantity">
                <v-icon icon="mdi-basket-outline"></v-icon>
                  {{ product.total_orders_quantity }}
                </p>
              </div>

              <v-card-text class="py-0 pr-8" v-html="product.description">
              </v-card-text>
              <v-card-actions style="justify-content: center">
                <v-btn
                  style="
                    color: #6BA540;
                    border: 1px solid #6BA540;
                    border-radius: 20px;
                  "
                  @click="addcart(product.id)">
                    {{ $t('Add_cart') }}
                  <v-icon icon="mdi-cart-outline" class="px-3"></v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-snackbar v-model="showSnackbar" color="green-darken-3
">
     {{ $t('Added_successfully') }}
    <template v-slot:action="{ attrs }">
      <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
        </v-container>
         
  <v-container v-else>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader
          class="mx-auto border-0"
          type="paragraph"
        ></v-skeleton-loader>
      </v-col>

      <v-col cols="2" v-for="text in texts" :key="text">
        <v-skeleton-loader
          class="mx-auto border-0"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
    </div>
</template>
<script>
import CrudDataService from "../../Service/CrudDataService";
export default {
  props: {
    productfil: Array
  },
  data() {
    return {
    texts: [1, 2, 3,4,5,6],
        items: [
      {
        title: "Home",
        disabled: false,
        href: "/",
      },
      {
        title: "Products_offer",
        disabled: false,
        href: `/productshaveoffer/${this.$route.params.params}`
      },
    ],
      tab: null,
      rating: 4.5,
      products: [],
      showSnackbar :false

    };
  },
  methods: {
  async addcart(id){
    let res=await CrudDataService.create(`cart`,{
        product_id: id,
        quantity: 1,
      })
      if (res.data.success) {
      this.showSnackbar=true
    }
    },
    getIcon(product) {
      return product.isfavourite ? "mdi-heart" : "mdi-heart-outline";
    },
    detail(id) {
      this.$router.push({ name: "Detail", params: { id } });
    },
    async allproduct() {
      if (this.$route.params.params) {
        let res = await CrudDataService.getAll(`products?filter[offers]=${this.$route.params.params}`);
      this.products = res.data.data.data;
      }
    },
    async toggleHeart(product) {
      let res = await CrudDataService.create(
        `products/${product.id}/favorites`,
        ""
      ).then(() => {
        product.isfavourite = !product.isfavourite;
      });
    },
    async updateRating(productId, rating) {
      // Call your API to update the rating
      try {
        await CrudDataService.create(`products/${productId}/reviews`, {
          rating: rating,
        });
        // Handle success - e.g., show a message or update local state
      } catch (error) {
        // Handle error - e.g., show an error message
        console.error("Error updating rating:", error);
      }
    },
  },
  mounted() {
    this.allproduct();
  },
};
</script>
<style lang="">
    
</style>