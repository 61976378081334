<template>
  <v-card class="mx-auto my-3 pa-4 bg-transparent customer" variant="0">
    <div class="text-center">
      <img :src="Customer" alt="" />
      <h3>
        {{ $t("Customer_opinions") }}
      </h3>
    </div>

        <v-row justify="center" class="mt-10">
          <v-col cols="12" md="6">
            <v-card class="mx-auto my-3 pa-4 bg-transparent review" variant="0">
              <div class="d-flex">
                <div>
                  <img :src="user" alt="" />
                </div>
                <div class="w-75">
                  <v-rating
                    :model-value="formData.rating"
                    color="amber"
                    density="compact"
                    hover
                    size="small"
                    class="px-0"
                    @update:model-value="updateRating($event)"
                  ></v-rating>
                  <v-text-field
                    type="text"
                    density="compact"
                    variant="solo"
                    single-line
                    hide-details
                    rounded="0"
                    class="border-0 mt-3 comment"
                    style="border-radius: 5px"
                    v-model="formData.comment"
                    :placeholder="$t('addcomment')"

                  >
                  </v-text-field>
                  <v-btn
                    class="rounded mt-5"
                    style="background-color: #6ba540; color: white"
                    variant="text"
                    @click="addcomment"
                  >
                    {{ $t("addcomment") }}
                  </v-btn>
                  <div v-if="errormessage" class="text-red">
                    {{ errormessage }}
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
          </v-row>
          <v-window
      v-model="window"
      class="elevation-0 position-relative"
      :show-arrows="false"
    >
      <v-window-item v-for="(items, i) in groupedProducts" :key="i">
        <v-row class="mt-10">
          <v-col cols="12" md="6" v-for="(item, index) in items" :key="index" >
            <v-card class="mx-auto my-3 pa-4 bg-transparent review" variant="0">
              <div class="d-flex">
                <div>
                  <img :src="user" alt="" />
                </div>
                <div>
                  <v-rating
                    :model-value="item.rating"
                    color="amber"
                    density="compact"
                    hover
                    size="small"
                    class="px-0"
                  ></v-rating>
                  <p style="word-break: break-all;">
                    {{ item.comment }}
                  </p>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-window-item>
      <div
        class="position-absolute d-flex justify-space-between"
        style="top: 0%; left: 0"
      >
        <v-btn icon @click="next" class="right_btn mx-1">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon @click="prev" class="left_btn mx-1">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </v-window>
    <v-snackbar
      v-model="showSnackbar"
      color="#6ba540"
    >
      {{ $t("Your_reviewed") }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import Customer from "../../assets/image/financialdvisor.png";
import user from "../../assets/image/user.png";
export default {
  data() {
    return {
      window: 0,
      showSnackbar: false,
      errormessage: "",
      items: [],
      Customer,
      user,
      formData: {
        rating: "",
        comment: "",
      },
    };
  },
  computed: {
    // Group the products into sub-arrays of 3
    groupedProducts() {
      const groups = [];
      for (let i = 0; i < this.items.length; i += 2) {
        groups.push(this.items.slice(i, i + 2));
      }
      return groups;
    },
  },
  methods: {
    next() {
      this.window++;
      if (this.window >= this.groupedProducts.length) {
        this.window = 0; // Wrap around to the first window
      }
    },
    prev() {
      this.window--;
      if (this.window < 0) {
        this.window = this.groupedProducts.length - 1; // Wrap around to the last window
      }
    },
    async allreviews() {
      let res = await CrudDataService.getAll(
        `products/${this.$route.params.id}/reviews`
      );
      console.log(res.data.data);
      if (res.data.data.length > 0) {
        this.items = res.data.data;
      } else {
        this.items = ''
      }
    },
    async updateRating(rating) {
      this.formData.rating = rating;
    },
    async addcomment() {
      let res = await CrudDataService.create(
        `products/${this.$route.params.id}/reviews`,
        this.formData
      )
        .then((response) => {
          this.showSnackbar = true;
          this.errormessage = "";
          (this.formData.rating = ""), (this.formData.comment = "");
        })
        .catch((error) => {
          this.errormessage = error.data.message;
          console.log(error);
        });
    },
  },
  mounted() {
    this.allreviews();
  },
};
</script>
<style>

[dir="ltr"] .customer .v-btn--icon.v-btn--density-default {
  transform: rotateY(180deg) !important;
}
.comment .v-field {
  border-radius: 5px !important;
}
.customer .v-card {
  height: 90%;
}
</style>
<style scoped>
.itempro {
  height: 130px;
  border-radius: 6px;
  background: #eee;
}
.customer .review {
  border: 1px solid #6ba540;
  border-radius: 20px;
}
</style>
